import { Box, IconButton, InputAdornment, InputBase, Stack, debounce, styled } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import DropdownArrowIcon from 'components/shared/icons/DropdownArrow'
import { ColumnApi } from 'ag-grid-community'
import { SessionSearchState } from 'redux/slices'
import useSessionSearchFilterSlice from 'redux/slices/hooks/useSessionSearchFilter'
import PrimaryButton from 'components/runs/PrimaryButton'
import SelectV3 from 'components/runs/AdvancedRunSearchFilterPopover/SelectV3'
import AdvancedSessionSearchFilterPopover from './AdvanceSessionSearchFilterPopover'


interface SessionSearchFiltersProps {
  handleCancel?: (clear?: boolean) => void
  handleSearch: (searchFilters?: SessionSearchState) => void
  columnApi: ColumnApi | null
}

const SearchContainer = styled(Box)({
  minWidth: '893px',
  display: 'flex',
  flexGrow: 1,
  height: '32px',
  borderRadius: '5px',
})

const StyledSearchInput = styled(InputBase)({
  border: '1px solid #EDEDED',
  background: '#EDEDED',
  height: '32px',
  padding: '10px',
  borderRadius: '5px',
  marginLeft: '10px',
})

const Options = [
  { label: 'Recently Started', value: 'created_at' },
  { label: 'Session Name', value: 'name' },
  { label: 'Creator', value: 'author_email' },
]

export function SessionSearchFilter({
  handleCancel: handleCancelProp,
  handleSearch: handleSearchProp,
  columnApi,
}: SessionSearchFiltersProps): JSX.Element {
  const { update, sessionSearchFilter } = useSessionSearchFilterSlice()
  const [optionSelected, setOptionSelected] = useState(Options[0].value)

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const debounceHandleSearch = useCallback(debounce(handleSearchProp, 250), [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSessionSearchFilter: SessionSearchState = {
      ...sessionSearchFilter,
      keyword: e.target.value ? e.target.value.split(','):[],
    }
    update(newSessionSearchFilter)
    debounceHandleSearch(newSessionSearchFilter)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openPopper, setOpenPopper] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper(!openPopper)
  }

  const handleSearch = () => {
    handleSearchProp()
    setOpenPopper(false)
  }

  const handleCancel = (clear = true) => {
    if (handleCancelProp) handleCancelProp(clear)
    setOpenPopper(false)
  }

  const handleColumnSort = (colId: string) => {
    let columnState
    switch (colId) {
      case 'name':
        columnState = {
          state: [{ colId, sort: 'asc' }],
          defaultState: { sort: null },
        }
        break
      default:
        columnState = {
          state: [{ colId, sort: 'desc' }],
          defaultState: { sort: null },
        }
    }
   columnApi?.applyColumnState(columnState)
    setOptionSelected(colId)
  }

  useEffect(() => {
    return () => {
      update({ ...sessionSearchFilter, keyword: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      <Stack sx={{ height: '60px', pt: '13px', pb: '15px' }} direction="row" gap="12px">
        <SearchContainer data-testid="session-search-bar">
          <StyledSearchInput
            placeholder="Search"
            fullWidth
            onChange={handleChange}
            value={sessionSearchFilter.keyword ?? ''}
            endAdornment={
              <InputAdornment position="end">
                <IconButton data-testid="search-arrow-icon" sx={{ p: 0 }} onClick={handleClick}>
                  <DropdownArrowIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </SearchContainer>
        <Box data-testid="advanced-search-btn" sx={{ width: '165px', height: '32px' }}>
          <PrimaryButton sx={{ fontSize: '12px' }} onClick={handleClick}>
            {sessionSearchFilter.advancedSearchEnabled ? 'Refine' : 'Advanced'} Search
          </PrimaryButton>
        </Box>
        <Box data-testid="sort-sessions-table" sx={{ width: '161px', height: '32px' }}>
          <SelectV3
            size="small"
            items={Object.entries(Options).map((e) => ({
              key: e[0],
              value: e[1].value,
              output: e[1].label,
            }))}
            value={optionSelected}
            onChange={(event) => handleColumnSort(event.target.value as unknown as string)}
          />
        </Box>
      </Stack>
      <AdvancedSessionSearchFilterPopover
        open={openPopper}
        anchorEl={anchorEl}
        handleCancel={handleCancel}
        handleSearch={handleSearch}
      />
    </div>
  )
}

export default SessionSearchFilter
