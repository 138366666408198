import { Box } from '@mui/material'
import { ColumnApi, GridApi, GridReadyEvent, IDatasource, IGetRowsParams, SelectionChangedEvent } from 'ag-grid-community'
import { useEffect, useRef, useState } from 'react'
import { SessionSearchState } from 'redux/slices/SessionFilterSearchSlice'
import useSessionSearchFilterSlice from 'redux/slices/hooks/useSessionSearchFilter'
import { getSessions, GetSessionsParams, SessionMetaData } from 'utils/api'
import SplitterLayout from 'react-splitter-layout'
import { useQueryClient } from 'react-query'
import { LIMIT } from 'components/cell-browsing/constants'
import { sortModelItemToOrderBy } from 'components/runs/metadata'
import useCellVisualizationUrlParams from 'components/cell-visualizations/useCellVisualizationUrlParams'
import { usePlotlySlice } from 'redux/slices/hooks'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import ContentLoading from 'components/shared/ContentLoading'
import CellVisualizationTable from './tableView/CellVisualizationTable'
import SessionSearchFilter from '../SessionSearchFilter'
import useSessionQueryParams from '../useSessionQueryParams'
import SessionDetailsPanel from '../SessionDetailsPanel'
import { getCellVisualizationData } from './tableView/metadata'



export const CellVisualizationSessionSelection = (): JSX.Element => {
  const [sessions,setSessions]=useState<SessionMetaData[]>([])
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)
  const { search, query, setQuery } = useSessionQueryParams()
  const { sessionSearchFilter } = useSessionSearchFilterSlice()
  const [refetch, setRefetch] = useState<boolean | undefined>(false)
  const [tableGridApi, setTableGridApi] = useState<GridApi | null>(null)
  const [showDetailsPanel, setShowDetailsPanel] = useState<boolean>(false)
  const [selectedSession, setSelectedSession] = useState<number>(0)
  const queryClient = useQueryClient()
  const { updateSession } = useCellVisualizationUrlParams()
  const { resetRange } = usePlotlySlice()
  const eventsManager = useEventsManager()
  const { setStore } = useCellVisualizationsSlice()
  const [sessionsLoading, setSessionsLoading] = useState(false)
  
  useEffect(() => {
    if (refetch && tableGridApi) {
      setRefetch(false)

      setTimeout(() => {
        tableGridApi.onFilterChanged()
      }, 100)
    }
  }, [tableGridApi, refetch])
  
  

 const cellVisualizationSessionsParams = (
  state:SessionSearchState
) => {
  return {
    version_config_fields:state.versionConfigFields ? state.versionConfigFields : undefined,
    session_name:state.sessionName ? state.sessionName : undefined ,
    creator_email:state.email ? state.email :undefined, 
    keywords:state.keyword ? state.keyword : undefined ,
    classifier_name:state.classifierName ? state.classifierName : undefined,
    run_ids: state.runIds ? state.runIds : undefined,
    sample_id:state.sampleID ? state.sampleID :undefined,
  };
};

const apiParams = useRef<GetSessionsParams>(cellVisualizationSessionsParams(search))

function handleUpdate(searchFilters?:SessionSearchState) {
    let newApiParams: GetSessionsParams
    let storeToUse: SessionSearchState

    if (searchFilters) {
      storeToUse = searchFilters
      if (searchFilters.advancedSearchEnabled) {
       newApiParams = {
          ...apiParams.current,
          keywords: searchFilters.keyword ?? [],
        }
      } else {
        newApiParams = { keywords: searchFilters.keyword }
      }
    } else {
      
      storeToUse = {
        ...sessionSearchFilter,
        advancedSearchEnabled: true,
      }
     newApiParams = cellVisualizationSessionsParams(storeToUse)
    }

    apiParams.current = newApiParams

    const newQuery: SessionSearchState = {
      ...query,
      ...Object.keys(storeToUse).reduce((acc, k) => {
        const key = k as keyof typeof storeToUse
        const val = storeToUse[key]
       return { ...acc, [key]: val }
      }, {}),
    }
    setQuery(newQuery)
    setRefetch(true)
  }

  const deselectAndRefetch = () => {
    setRefetch(true)
  }

  const handleAdvancedSearchCancel = (clear = true) => {
    if (clear) {
      apiParams.current = {}
      deselectAndRefetch()
    }
  }
  
  useEffect(() => {
    // Clear query parameters on page refresh
    window.history.replaceState({}, '', window.location.pathname)
    apiParams.current = {}
  }, [])

    
const serverDataSource: IDatasource = {
    getRows: async (getRowParams: IGetRowsParams) => {
      const { startRow, successCallback, failCallback } = getRowParams
      const limit = getRowParams.endRow - getRowParams.startRow
      const orderBy = sortModelItemToOrderBy(getRowParams.sortModel) || 'created_at:desc'
      try {
        setSessionsLoading(true)
        const results = await queryClient.fetchQuery(
          ['getSessions', getRowParams.startRow],
          () =>
            getSessions({
              ...apiParams?.current,
              offset: getRowParams.startRow,
              limit,
              order_by:orderBy
            }),
          { staleTime: 0 }
        )
        const fetchedData = results.data.data
        let lastRow = -1
        if (fetchedData.length < LIMIT) {
          lastRow = startRow + fetchedData.length
        }
        const filteredSessions=fetchedData.filter((item)=> item.status === 'ready')
        setSessions(filteredSessions)
        successCallback(getCellVisualizationData(fetchedData), lastRow)
        setSessionsLoading(false)
      } catch (err) {
        console.error(err)
        failCallback()
      } finally {
        setSessionsLoading(false)
      }
    },
  }

  const onGridReady = (params: GridReadyEvent) => {
     params.api.sizeColumnsToFit()
     setTableGridApi(params.api)
      setColumnApi(params.columnApi)
    params.api.setDatasource(serverDataSource)
  }

  useEffect(() => {
    if (selectedSession > 0) {
      setShowDetailsPanel(true)
    } else {
      setShowDetailsPanel(false)
    }
  }, [selectedSession])
  

  function onRowClicked(event: SelectionChangedEvent) {
    const sessionId = event.api.getSelectedNodes().map((n) => n.data.sessionId)
    if(sessionId.length > 0){
      setSelectedSession(sessionId[0])
    }
   }

   const handleOpenSession = async (sessionId: number) => {
    eventsManager.sendSessionSelectionEvent(sessionId)
    setStore({ cellsData: undefined, cellInfoGroups: undefined, selectedDataset: undefined })
    resetRange()
    updateSession(sessionId)
  }

  return (
    <>
      <Box sx={{ pl: '16px', pr: '20px' }}>
        <SessionSearchFilter
          columnApi={columnApi}
          handleCancel={handleAdvancedSearchCancel}
          handleSearch={handleUpdate}
        />
      </Box>
      {sessionsLoading && (
        <ContentLoading
          sx={{
            position: 'absolute',
            zIndex: 2,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
           }}
        />
      )}
      {sessions && (
        <>
          <SplitterLayout percentage primaryIndex={0} secondaryMinSize={45}>
            <CellVisualizationTable
              tableGridApi={tableGridApi}
              onGridReady={onGridReady}
              onRowClicked={onRowClicked}
              handleOpenSession={handleOpenSession}
            />
            {showDetailsPanel ? (
              <Box sx={{ py: '16px', px: '15px', overflow: 'auto', paddingBottom: '150px' }}>
                <SessionDetailsPanel
                 handleOpenSession={handleOpenSession}
                 setShowDetailsPanel={setShowDetailsPanel}
                  selectedSession={selectedSession}
                  setSelectedSession={setSelectedSession}
                />
              </Box>
            ) : null}
          </SplitterLayout>
        </>
      )}
    </>
  )
}
export default CellVisualizationSessionSelection
