import { Stack } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import { isEqual } from 'lodash'
import { useMemo, useRef } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DifferentialFeaturesTaskInput, startDifferentialFeaturesTask, TaskStatus } from 'utils/api'
import { useQueryClient } from 'react-query'
import { usePlotData } from 'components/cell-visualizations/plot/usePlotData'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import useSessionApi from '../useSessionApi'
import { getCellInfoToGroups } from '../utils'
import { convertMorphotypeIdsToCellIds } from '../Pinned/DifferentialFeatures/morphotypeListUtils'

export const PreFilterButtons = (): JSX.Element => {
  const { exitPreFilter,sessionId,versionId } = useCellVisualizationUrlParams()
  const { cellInfoUnfiltered } = usePlotData()
  const queryClient = useQueryClient()
  const { saveVersionConfig } = useSessionApi()
  const {
    setCellInfoGroups,
    cellVisualizations: { cellsData, morphotypesToRemove, differentialFeaturesComparisons },
    prefilteredCellIdSet,
    visiblePinnedCells: pinnedCells,
    setMorphotypesToRemove,
    addDifferentialFeaturesComparison,
    deleteDifferentialFeaturesComparisonByTaskId,
  } = useCellVisualizationsSlice()

  const mountedCellIds = useRef(prefilteredCellIdSet)
  const mountedMorphotypesToRemove = useRef(morphotypesToRemove)

  const valsAreUnchanged = useMemo(
    () => isEqual(mountedCellIds.current, prefilteredCellIdSet),
    [prefilteredCellIdSet]
  )

  const refreshCellInfoGroups = () => {
    if (!cellsData) return
    const unFilteredData = cellsData?.filter((cd) => !prefilteredCellIdSet.has(cd.CELL_ID))
    if (unFilteredData.length) {
      setCellInfoGroups(getCellInfoToGroups(unFilteredData))
    }
  }

  const startComparison = async (morphotypeIds1:number[],morphotypeIds2:number[]) => {

      const payload: DifferentialFeaturesTaskInput = {
        sessionId,
        versionId: versionId + 1,

        cellIdList1: convertMorphotypeIdsToCellIds(
          morphotypeIds1,
          morphotypeIds2,
          cellInfoUnfiltered,
          pinnedCells
        ),
        cellIdList2: convertMorphotypeIdsToCellIds(
          morphotypeIds2,
          morphotypeIds1,
          cellInfoUnfiltered,
          pinnedCells
        ),
      }

      try {
        const result = await queryClient.fetchQuery(
          ['startDifferentialFeaturesTask', payload],
          startDifferentialFeaturesTask
        )
        const { task_id: taskId } = result.data

        addDifferentialFeaturesComparison({
          morphotypeIds1,
          morphotypeIds2,
          taskId,
          status: TaskStatus.PENDING,
        })
      } catch (error) {
        console.error('Error starting differential features task', error)
      }
    
  }

  const onSave = async () => {
    await saveVersionConfig()
    refreshCellInfoGroups()
    differentialFeaturesComparisons?.slice().reverse().map((comparison) => {
      startComparison(comparison.morphotypeIds1, comparison.morphotypeIds2)
      deleteDifferentialFeaturesComparisonByTaskId({taskId:comparison.taskId})
      return null
    })
  }
  

  const onSkip = () => {
    if (!isEqual(mountedMorphotypesToRemove.current, morphotypesToRemove)) {
      setMorphotypesToRemove(mountedMorphotypesToRemove.current)
    }
    exitPreFilter()
  }

  return (
    <Stack direction="row" spacing={2}>
      <DeepcellPrimaryButton
        sx={{ height: '37px', width: '240px', border: '1px solid rgba(164, 239, 192, 1)' }}
        contained
        onClick={onSkip}
      >
        Skip to cell visualization
      </DeepcellPrimaryButton>
      <DeepcellPrimaryButton
        sx={{ height: '37px', width: '172px', border: '1px solid rgba(164, 239, 192, 1)' }}
        onClick={onSave}
        contained
        disabled={valsAreUnchanged}
      >
        Save & Continue
      </DeepcellPrimaryButton>
    </Stack>
  )
}

export default PreFilterButtons
